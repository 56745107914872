@charset "utf-8"
@import '../node_modules/bulma/sass/utilities/initial-variables.scss'
@import "../node_modules/bulma/sass/utilities/functions.scss"
@import '../node_modules/bulma/sass/utilities/mixins.scss'
@import '../node_modules/bulma-switch-control/css/main.min.css'
// @import '../node_modules/bulma/sass/helpers/color.scss'
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap')
@import '../node_modules/cooltipz-css/cooltipz.min.css'
@import 'intro.js/introjs.css'
//@import 'intro.js/themes/introjs-modern.css'

$blue: #3A94E5

$cream: #F9F6F0
$primary: $blue
$white-ter: $cream

@import '../node_modules/bulma/sass'



.has-background-custom
  background-color: $cream


// .App-header 
//   background-color: #222
//   height: 36px
//   padding: 20px
//   color: black

// .App-title 
//   font-size: 1.5em

// .App-intro 
//   font-size: large

.handwriting 
  font-family: "Open Sans", sans-serif
  font-optical-sizing: auto
  font-weight: 500
  font-style: normal

  h1, h2
    font-family: "Montserrat", sans-serif
    font-optical-sizing: auto
    font-weight: 700
    font-style: normal

// .flows-tab-content
//   width: 100%

.navbar-item
  .navbar-center
    flex-grow: 1
    flex-direction: column
    justify-content: center

@keyframes App-logo-spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.box
  border-radius: 0.4rem

textArea  
    // padding: 10px
    // padding-right: 20px

    // line-height: 1.4em
    // width: 100%
    // border: none
    // outline: none !important
    // border-left: 10px solid #eee
    resize: none

.floatBottom
  position: fixed
  bottom: 0
  left: 0
  z-index: 999
  width: 100%
  height: 33%
  padding: 10px
  background-color: #fff
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1)
  color: #333

.floatRight
  position: fixed
  right: 0
  z-index: 999
  height: 100%
  width: 600px
  padding: 10px
  background-color: #fff
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1)
  color: #333

.logoImage
  position: fixed
  bottom: 10px
  right: 10px
  z-index: 999
  width: 200px



@media print
  @page 
      margin-top: 1in
      margin-left: 0.3in
      margin-right: 0.3in
      size: landscape
    
  .navbar
    display: none !important
  
  .box
    box-shadow: none

  .flow
    width: 1500px

  .flow-card
    width: 180px
  

  .no-print, .no-print *
    display: none !important

  #to-print
    width: 1500px

  h2.title, table
    page-break-before: always

  .page-break-before
    page-break-before: always

  nav 
    display: none

  // html, body 
  //   overflow: auto
    

  body
    font-size: 110%
    background-color: white    

  body > #root > div
    background-color: white  
  
    h1, h2, h3, h4, h5, span, div, textarea, input, p
      font-size: inherit
    
    // .canvas-section
      // page-break-inside: avoid

    .textarea
      font-size: inherit
      border: none
      padding: 0
      margin: 0
      resize: none

  .sparkline
    display: block
    // background-color: hsl(0, 8.50%, 50%) !important
    height: 1.2em      

  footer.pdf 
      display: block
      position: fixed
      bottom: 0
      background: white
      margin-left: 0.7in
      color: $grey-light

      a
        color: $grey-dark
    
  

// ====== END PRINT ======
footer.footer
  background-color: $dark
  background-blend-mode: exclusion

footer.pdf
  display: none



body
  height: 100%
  background-image: url("../public/appflows-squiggle-background.svg") 
  background-size: cover  
  
body > #root > div
  min-height: 100vh  

// .flow h1
//   margin-top: 0

.flow.paper
  filter: url(#filter_tornpaper)
  background: #F48220
  // background: #F6F0E5 //cream
  
  padding: 2rem
  color: white
  
  h2
    color: white

.flow.paper.cream
  background: #ffffff
  color: #36322A
  h2
    color: #36322A
  img
    filter: sepia(0.08)

.flow
  

.flow-card
  padding: 1rem
  display: inline-block
  width: 140px
  vertical-align: top
  background: rgba(0,0,0,0)
  scroll-margin-top: 300px

  img.flow-screen
    max-width: 110px

  h1
    font-size: 1rem
    font-weight: 600
    margin-bottom: 0.1em

  .flow-card-label
    line-height: 1.4em
    font-size: 0.75rem

// .flow-card.selected 
//     .flow-card-label
//       background-color: rgb(253, 255, 207)

.flash 
  animation: yellowflash-bg 2s

@keyframes yellowflash-bg 
  from 
    background: rgba(239,214,23,0.2)
  to 
    background: transparent

@keyframes shake 
  10%, 90% 
    transform: translate3d(-1px, 0, 0)
  
  20%, 80% 
    transform: translate3d(2px, 0, 0)

  30%, 50%, 70% 
    transform: translate3d(-4px, 0, 0)

  40%, 60% 
    transform: translate3d(4px, 0, 0)
  

.grow
  transition: all .2s ease-in-out

.grow:hover
  transform: scale(1.1)

// #editor
//   height: 500px
//   overflow: scroll

.dropdown-menu
  // position: fixed !important
  z-index: 30

.dropdown-wide
  width: 20rem

.fixed
  position: sticky
  top: 60px

.modal-card
  width: 80%

.growth-results
  h4
    @extend .subtitle, .is-6, .mb-1, .mt-3, .is-uppercase, .has-text-weight-semibold
    color: $primary
    span 
      @extend .has-text-weight-bold
      color: $primary
  
table.table
  td, th
    vertical-align: middle

.growth .column 
  display: flex

.tabs li.is-active a, .tabs.is-toggle-rounded li a
  border-width: 0px
  border: none  
  
.tabs > ul > li a.has-text-white
  background-color: $white-ter
  span
    color: rgba(0, 0, 0, 0)

.tabs > ul > li.is-active a
  font-weight: bold  
  span
    color: $white
  
.tabs .doc-tabs   
  border: 0px
  ul
    border: 0px
  a
    color: $dark
  ul > li.is-active > a, li a
    color: $dark

div.box.is-option
  border: 2px solid $grey-light
  color: $grey
  background-color: $white-ter
  h4
    color: $grey
  
div.box.is-option.is-active
  border: 2px solid $primary
  color: $primary
  background: $white
  h4
    color: $primary
    
div.toastui-editor-defaultUI
  background-color: #FCFBF8
  
  .toastui-editor-toolbar
    
  .toastui-editor-md-tab-container, .toastui-editor-defaultUI-toolbar
    background-color: #FCFBF8

.grow-shrink
  animation-name: grow-shrink
  animation-duration: 2s
  animation-iteration-count: infinite
  
  // background-color: orange
  // border-radius: 10px
  // height: 100px
  // left: 0
  // margin-left: auto
  // margin-right: auto
  // position: absolute
  // right: 0
  // top: 100px
  // width: 100px

pre.contenteditable-paragraph-readonly
  font-family: "Open Sans", sans-serif
  background-color: $white
  font-size: 1.0rem
  font-weight: normal
  padding: 0rem
  white-space: pre-line

pre.contenteditable-paragraph
  font-family: "Open Sans", sans-serif
  background-color: $white
  font-size: 1.0rem
  font-weight: normal
  padding: 0rem
  white-space: pre-line

  // border: 1px dashed $white-ter

  &:focus
    border: 1px dashed $warning
    border-radius: 10px
    padding: 1rem
  
  &:hover
    color: $link
  
.empty
  border: 1px dashed $grey-lighter
  background-color: $white-ter
  border-radius: 10px
  padding: 1rem


@keyframes grow-shrink
  40% 
    transform: scale(1.4) 
  80% 
    transform: scale(1) 

.has-max-width-60rem
  // @include mobile
  // @include tablet
  @include desktop
    max-width: 60rem
  @include widescreen
    max-width: 60rem